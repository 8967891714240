<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ข้อมูลรถ
      </v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <div class="mx-4 pb-4 box box-2" style="min-height: 100%;">
      <v-card height="100%" class="px-2 pt-2">
        <v-row style="height: 100%">
          <v-col class=" pt-6 ma-0">
            <v-row class="pl-2">
              <v-col>
                ชื่อรถ
              </v-col>
              <v-col>
                {{ vehicle.name }}
              </v-col>
            </v-row>
            <v-row class="pl-2">
              <v-col>
                ทะเบียนรถ
              </v-col>
              <v-col>
                {{ vehicle.licence }}
              </v-col>
            </v-row>
            <v-row class="pl-2">
              <v-col>
                ประเภทรถ
              </v-col>
              <v-col>
                {{ vehicle.type.name }}
              </v-col>
            </v-row>
            <v-row class="pl-2">
              <v-col>
                บันทึกการเติมน้ำมัน
              </v-col>
            </v-row>
            <apexchart width="95%" height="400px" type="bar" :options="options"
                       :series="oilDataDashboard"></apexchart>
            <v-data-table :items="oils" :headers="oilHeader" :header-props="{ sortIcon: null }">
              <template v-slot:item.action="{item}">
                <v-btn icon @click="goOilDetail(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <v-divider vertical/>
          <v-col class="pt-6 pl-1 ma-0">
            <v-row class="pl-2">
              <v-col>
                รายการซ่อม
              </v-col>
              <v-col>
                {{ maintenanceForCount.length }} รายการ
              </v-col>
            </v-row>
            <v-row class="pl-2 mb-4">
              <v-col>
                ค่าใช้จ่ายทั้งหมด
              </v-col>
              <v-col>
                {{ totalMaintenancePrice }} บาท
              </v-col>
            </v-row>
            <apexchart width="95%" height="400px" type="bar" :options="options"
                       :series="maintenanceDataDashboard"></apexchart>
            <v-row class="pl-5 my-4">
              รายการซ่อมล่าสุด
              <v-spacer/>
              <v-btn small class="mr-4" color="primary"
                     @click="$router.push({path:`/oil-tracking/vehicle/${$route.params.id}`})"
                     depressed>
                ดูรายการซ่อมของรถคันนี้ทั้งหมด
              </v-btn>
            </v-row>
            <v-data-table
                :header-props="{ sortIcon: null }"
                class="mt-2"
                :headers="maintenanceHeader"
                :items="maintenances">
              <template v-slot:item.maintenance_item="{item}">
                <p class="text-truncate" style="margin-bottom: 0">
                  {{ textMaintenanceItem(item.maintenance_item) }}
                </p>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DateTime from "luxon/src/datetime";

function nameMonth(number) {
  return DateTime.now().minus({month: number}).setLocale('th').toFormat('LLL')
}

export default {
  name: "OilTrackerDetailByCar",
  data() {
    return {
      options: {
        // chart: {
        //   id: 'vuechart-example'
        // },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: [nameMonth(11), nameMonth(10), nameMonth(9), nameMonth(8), nameMonth(7), nameMonth(6), nameMonth(5), nameMonth(4), nameMonth(3), nameMonth(2), nameMonth(1), nameMonth(0)]
        }
      },
      vehicle: {},
      maintenances: [{}],
      oils: [],
      oilHeader: [
        {
          text: 'วันที่',
          value: 'date'
        },
        {
          text: 'ราคา',
          value: 'price'
        },
        {
          text: 'ลิตร',
          value: 'liter',
          align: 'center'
        },
        {
          text: 'กิโลเมมตร(โดยประมาณ)',
          value: 'calculate.distance',
          align: 'center'
        },
        {
          text: '',
          value: 'action',
          align: 'center'
        }
      ],
      maintenanceHeader: [
        {
          text: 'วันที่',
          value: 'date'
        },
        {
          text: 'รายการ',
          value: 'maintenance_item'
        },
        {
          text: 'ราคา',
          value: 'total_price'
        }
      ],
      maintenanceForCount: {},
      maintenanceDataDashboard: [],
      oilDataDashboard: []
    }
  },
  created() {
    this.getVehicle()
  },
  methods: {
    async getVehicle() {
      axios.get(`/api/tracks/vehicle/${this.$route.params.id}/`)
          .then(res => {
            console.log(res.data.data)
            this.vehicle = res.data.data.vehicle
            this.oils = res.data.data.oil
            this.maintenances = res.data.data.maintenance
            this.maintenanceForCount = this.maintenances.map(a => ({...a}));
            // this.maintenance.length = 10
          })
          .catch(e => {
            console.log(e)
          })
      this.maintenanceDataDashboard = (await axios.get(`/api/dashboards/maintenance/?vehicle_id=${this.$route.params.id}`)).data.data.y
      this.oilDataDashboard = (await axios.get(`/api/dashboards/oil/?vehicle_id=${this.$route.params.id}`)).data.data.y
      console.log(this.maintenanceDashboard)
    },
    textMaintenanceItem(item) {
      let str = ""
      item.forEach((i, index) => {
        str += (`${i.part.name} ${index !== item.length - 1 ? ', ' : ''}`)
      })
      return str
    },
    goOilDetail(item) {
      console.log(item)
      this.$router.push({path: `/oil-tracking/vehicle/oil/${item.id}`})
    }
  },
  computed: {
    totalMaintenancePrice() {
      let sum = 0
      this.maintenances.forEach(i => {
        sum += i.total_price
      })
      return sum
    }
  }
}
</script>

<style scoped>

</style>